import React, { useEffect } from 'react';

const pixelIds = ['789246286109215'];

const loadFacebookPixels = () => {
  // 789246286109215 Pebbles
  // 1204955830219144 Brakeup
  // 238128798951989 Thalwart

  // console.log("loadFacebookPixel");

  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );

  //Initialize and track events for each Pixel ID
  pixelIds.forEach((pixelId) => {
    // console.log("loadFacebookPixel222", pixelId);

    window.fbq('init', pixelId);
    window.fbq('track', 'PageView');
  });

  // Clean up the Facebook Pixel on component unmount
  return () => {
    pixelIds.forEach((pixelId) => {
      window.fbq('consent', 'revoke');
    });
  };
};

export default loadFacebookPixels;
